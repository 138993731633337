import React, { useState } from 'react'
import { isMobile, isTablet, isIPad13 } from 'react-device-detect';
import davidHeadshot from './photos/David.Darley2-Edit.jpg'
import faceoffLegup from './photos/FaceoffLegup.jpg'
import mouthOpen from './photos/MouthOpen.jpg'
import mullet from './photos/Mullet.jpg'

import './Draw.css'

function Draw() {
  let [ctx, setCtx] = useState();
  let [canvas, setCanvas] = useState();
  const [hovered, setHovered] = useState(false);
  let buttonHoverStyle;
  let imageLoopStyle = {
    width: '25px'
  }

  function handleLoadEvent() {
    const canv = document.getElementById('draw');
    const context = canv.getContext('2d');
    let image = document.getElementById('mainPic');
    let imagePosition = image.getBoundingClientRect();
    let imageLeft = imagePosition.left;
    let imageTop = imagePosition.top;
    canv.width = image.width;
    canv.height = image.height;
    context.moveTo(imageLeft, imageTop);
    context.drawImage(image, imageLeft, imageTop);
    setCanvas(canv);
    setCtx(context);
    context.clearRect(0, 0, canv.width, canv.height)
  }

  let photos = [davidHeadshot, faceoffLegup, mouthOpen, mullet];

  let [lastX, setLastX] = useState(0);
  let [lastY, setLastY] = useState(0);
  let [brushColor, setBrushColor] = useState('#7BF9F3');
  let [brushSize, setBrushSize] = useState(10);
  let [isDrawing, setIsDrawing] = useState(false);
  let [currentImage, setCurrentImage] = useState(photos[0]);
  let [controlsVisible, setControlsVisible] = useState(true);


  function beginDrawingWeb(e) {
    const x = e.nativeEvent.offsetX;
    const y = e.nativeEvent.offsetY;
    drawLine(e, x, y);
  }

  function beginDrawingMobile(e) {
    if (e.touches.length > 1) {
      return;
    }
    const touchX = e.nativeEvent.touches[0].clientX;
    const touchY = e.nativeEvent.touches[0].clientY;
    drawLine(e, touchX, touchY);
  }

  function drawLine(e, x, y) {
    e.preventDefault();
    if (!isDrawing) return;
    ctx.lineWidth = brushSize;
    ctx.lineJoin = 'round';
    ctx.lineCap = 'round';
    ctx.strokeStyle = brushColor;
    ctx.beginPath();
    // start from
    ctx.moveTo(lastX, lastY);
    // go to
    ctx.lineTo(x, y);
    ctx.stroke();
    setLastX(x);
    setLastY(y);
  }

  function handlePhotoClick(index) {
    setCurrentImage(photos[index])
  }

  function handleBrushSizeChange() {
    let inputVal = document.getElementById('brushRange').value;
    setBrushSize(inputVal);
  }
  function handleBrushColorChange() {
    let inputVal = document.getElementById('brushColor').value;
    setBrushColor(inputVal);
  }

  function handleControlToggle() {
    let controls = document.getElementById('brushControls');
    if (controlsVisible) {
      controls.setAttribute('style', 'display:none');
      setControlsVisible(false)
    } else {
      controls.removeAttribute('style');
      setControlsVisible(true);
    }
  }

  function handleClearClick() {
    const canv = canvas;
    const context = ctx;
    context.clearRect(0, 0, canv.width, canv.height)
  }
  if (hovered) {
    buttonHoverStyle = {
      background: brushColor,
      borderColor: brushColor
    }
  }
  if (isMobile || isTablet || isIPad13) {
    return (
      <div id="container">
        <div id="mobileBrush">
          <button onClick={handleControlToggle}>
            {controlsVisible ? 'Hide' : 'Show'} Controls
          </button>
          <div id="brushControls">
            <div id="photoSelection">
              Select photo
              <ul>
                {photos.map((val, i) => {
                  return <li onClick={() => { handlePhotoClick(i) }} key={i}><img style={imageLoopStyle} src={val}></img></li>;
                })}
              </ul>
            </div>
            <p>Make Me Beautiful</p>
            <p>Set Brush Size</p>
            <input id="brushRange" type="range" min="1" max="100" defaultValue="5" onChange={handleBrushSizeChange}></input>
            <p>Set <span style={{ color: brushColor }}>Brush Color</span></p>
            <input id="brushColor" type="color" defaultValue="#7BF9F3" onChange={handleBrushColorChange}></input>
            <br />
            <button
              className="clearButton"
              style={buttonHoverStyle}
              onClick={handleClearClick}
              onTouchStart={() => setHovered(true)}
              onTouchEnd={() => setHovered(false)}
            >Clear Drawing
            </button>
          </div>
        </div>
        <img onLoad={handleLoadEvent} id="mainPic" alt="My Beautiful Headshot" src={currentImage}></img>
        <canvas id="draw"
          onTouchEnd={() => { setIsDrawing(false) }}
          onTouchCancel={() => { setIsDrawing(false) }}
          onTouchStart={(e) => {
            setIsDrawing(true);
            setLastX(e.nativeEvent.touches[0].clientX);
            setLastY(e.nativeEvent.touches[0].clientY)
          }}
          onTouchMove={(e) => beginDrawingMobile(e)}
        ></canvas>
      </div>
    )
  } else {
    return (
      <div id="container">
        <div id="brush">
          <div>
            Select Photo
            <ul>
              {photos.map((val, i) => {
                return <li onClick={() => { handlePhotoClick(i) }} key={i}><img style={imageLoopStyle} src={val}></img></li>;
              })}
            </ul>
          </div>
          <h1>Make Me Beautiful</h1>
          <h3>Set Brush Size</h3>
          <input id="brushRange" type="range" min="1" max="100" defaultValue="5" onChange={handleBrushSizeChange}></input>
          <h3>Set <span style={{ color: brushColor }}>Brush Color</span></h3>
          <input id="brushColor" type="color" defaultValue="#7BF9F3" onChange={handleBrushColorChange}></input>
          <br />
          <br />
          <button
            className="clearButton"
            style={buttonHoverStyle}
            onClick={handleClearClick}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
          >Clear Drawing
          </button>
        </div>
        <img onLoad={handleLoadEvent} id="mainPic" alt="My Beautiful Headshot" src={currentImage}></img>
        <canvas id="draw"
          onMouseLeave={() => { setIsDrawing(false) }}
          onMouseUp={() => { setIsDrawing(false) }}
          onMouseDown={(e) => {
            setIsDrawing(true);
            setLastX(e.nativeEvent.offsetX);
            setLastY(e.nativeEvent.offsetY)
          }}
          onMouseMove={(e) => beginDrawingWeb(e)}
        ></canvas>
      </div>
    )
  }
}

export default Draw;