import React from 'react';
import './App.css';
import Draw from './Draw.js'

function App() {
  return (
    <div className="App">
      <Draw />
    </div>
  )
}

export default App;


// in app js, use react device detect to render mobile app if user is on mobile
// else keep displaying same crappy app as before.
